// src/components/Portfolio.js
import React from 'react';
import portfolioData from './PortfolioData.json';
import './Portfolio.css';

function Portfolio() {
    const data = portfolioData.portfolio;

    return (
        <section id= "portfolio">
            <div className="container" style={{ padding: '25px 50px' }}>
            <h1>{data.title}</h1>
            <p>{data.introduction}</p>
            
            <ul className="project-list" style={{ paddingLeft: '0px' }}>
                {data.projects.map(project => (
                    <li key={project.id} className="project-item">
                        <a href={project.link}><h2>{project.name}</h2></a>
                        {project.descriptions.map((desc, index) => (
                            typeof desc === 'string' ? (
                                <p key={index}>{desc}</p>
                            ) : (
                                <div key={index} className="portfolio-img-container">
                                    <img src={desc.image} className={desc.class || ''} alt={`Project ${project.name} image ${index + 1}`} />
                                </div>
                            )
                        ))}
                        {project.images && project.images.length > 0 && (
                            <div className="portfolio-img-flex-container">
                                {project.images.map((image, index) => (
                                    <div key={index} className="portfolio-img-flex-item">
                                        <img src={image} alt={`Project ${project.name} image ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            </div>
        </section>
    );
}

export default Portfolio;



