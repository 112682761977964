import React, { useState, useEffect, useRef } from 'react';
import './NavBar.css';  // Ensure your CSS file is linked

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // State to handle menu toggle
    const navRef = useRef(null); // Ref for accessing the nav element

    // Function to toggle the state
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Function to handle route change
        const handleRouteChange = () => {
            // Scroll to the top of the page
            window.scrollTo(0, 0);
        };

        // Listen for route changes and call handleRouteChange function
        const unlisten = () => {
            if (navRef.current) {
                navRef.current.addEventListener('click', handleRouteChange);
            }
        };

        // Clean up the listener
        return () => {
            if (navRef.current) {
                navRef.current.removeEventListener('click', handleRouteChange);
            }
        };
    }, []);

    return (
        <div className='navbarContainer'>
            <nav className="navbar" ref={navRef}>
                <div className='navbar-header'>
                    <a className="navbar-brand" to="/">
                        <img src='/Images/PS.png' alt="Logo" className='navbar-logo'/>
                    </a>
                    <div className="hamburger" onClick={toggleMenu}>☰</div>
                </div>
                <ul className={`navbar-nav ${isOpen ? 'open' : ''}`}>
                    <li className="nav-item">
                        <a href="#home" onClick={toggleMenu}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="#about" onClick={toggleMenu}>About</a>
                    </li>
                    <li className="nav-item">
                        <a href="#portfolio" onClick={toggleMenu}>Portfolio</a>
                    </li>
                    <li className="nav-item">
                        <a href="#resume" onClick={toggleMenu}>Resume</a>
                    </li>
                    <li className="nav-item">
                        <a href="#contact" onClick={toggleMenu}>Contact</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;

