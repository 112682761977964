import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; // Import CSS file for styling

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = 'service_90ytdam';
        const templateId = 'template_6hamw5g';
        const publicKey = '2yy-o9DOI31HBswpt';

        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'Brandon',
            message: message,
        };

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log("Email sent successfully", response);
                setEmail('');
                setName('');
                setMessage('');
            })
            .catch((error) => {
                console.error("Error sending email", error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'user_name') {
            setName(value);
        } else if (name === 'user_email') {
            setEmail(value);
        } else if (name === 'message') {
            setMessage(value);
        }
    };

    return (
        <section id = "contact">
            <div className="contact-container">
            <h1>Contact Me</h1>
            <p>You can reach me at <a href="mailto:Brandonkohler2000@gmail.com">Brandonkohler2000@gmail.com</a></p>
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="contact_number" value="697483" />
                <div className="form-group">
                    <label>Name:</label>
                    <input type="text" name="user_name" value={name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input type="email" name="user_email" value={email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Message:</label>
                    <textarea name="message" value={message} onChange={handleChange} required />
                </div>
                <button type="submit">Send</button>
            </form>
            </div>
        </section>
    );
};

export default Contact;


