// src/App.js

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navigation/NavBar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Portfolio from './components/Portfolio/Portfolio';
import Resume from './components/Resume/Resume';

import './App.css';

function App() {
  return (
    <div className='App'>
        <Navbar />
        <Home />
        <About />
        <Portfolio />
        <Resume />
        <Contact />

      <footer className='footer'>
        <ul className="footer-list" style={{ paddingLeft: 0 }}>
          <li>
            <a href="https://www.linkedin.com/in/brandon-kohler-1788391b7/" target="_blank" rel="noopener noreferrer">
              <img src='/Images/footer1.png' alt="Footer 1" />
            </a>
          </li>
          <li>
            <a href="https://github.com/Kohler123" target="_blank" rel="noopener noreferrer">
              <img src='/Images/footer2.png' alt="Footer 2" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;




